
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

























































































.special-numbers-table__table {
  @extend %text-center;

  width: 100%;

  tr:first-child td:first-child {
    border-top-left-radius: 1rem;
  }

  tr:first-child td:last-child {
    border-top-right-radius: 1rem;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 1rem;
  }

  tr:last-child {
    border-bottom: 1px solid $c-gray-lightest;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 1rem;
  }

  tr:first-child th:first-child {
    border-top-left-radius: 1rem;
  }

  tr:first-child th:last-child {
    border-top-right-radius: 1rem;
  }

  tr:last-child th:first-child {
    border-bottom-left-radius: 1rem;
  }

  tr:last-child th:last-child {
    border-bottom-right-radius: 1rem;
  }
}

.special-numbers-table__table__row {
  &:nth-child(odd) {
    background-color: $c-light-gray;
  }

  &:first-child {
    background-color: $c-pink-medium;
  }
}

.special-numbers-table__table__row__title {
  @extend %text-uppercase;

  padding: $spacing / 2;
  color: $white;
  font-family: $ff-alt;
  line-height: 1.5;
}

.special-numbers-table__table__row__value {
  padding: $spacing / 2;
  border-right: 1px solid $c-gray-lightest;
  border-left: 1px solid $c-gray-lightest;
}
